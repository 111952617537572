import React from "react"
import axios from "axios"
import areaData from "../../../static/areas.json"
const defaultState = {
    areaList: []
}

const GlobalContext = React.createContext(defaultState)

class GlobalProvider extends React.Component {
  state = {
    areaList: areaData,
    gridView: false,
    areaupdate: false
  }

  // get area list
  // getitems = async url => {
  //   try {
  //     const { data } = await axios.get(url, {
  //       headers: {
  //         Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
  //       }
  //     })
  //     this.setState({ areaList: data, 
  //       areaupdate: true })
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }

  toggleLayout = () => {
    let gridView = !this.state.gridView
    this.setState({ gridView })
  }

  // componentDidMount() {
  //   // do api calls
  //   let url = process.env.GATSBY_STRAPI_SRC + "/areas.json"
  //   this.getitems(url)
  // }

  render() {
    const { children } = this.props
    return ( 
      // this.state?.areaupdate ?
      <GlobalContext.Provider
        value={{
          ...this.state,
          toggleLayout: this.toggleLayout
        }}
      >
        {children}
      </GlobalContext.Provider>
      // : null
    )
  }
}

export default GlobalContext

export { GlobalProvider }
